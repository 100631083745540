
<template>
  <v-window show-arrows>
    <template v-slot:prev="{ on, attrs }">
      <v-icon
        x-large
        color="white"
        v-bind="attrs"
        v-on="on"
      >mdi-arrow-left-drop-circle</v-icon>
    </template>
    <template v-slot:next="{ on, attrs }">
      <v-icon
        x-large
        color="white"
        v-bind="attrs"
        v-on="on"
      >mdi-arrow-right-drop-circle</v-icon>
    </template>
    <div v-if="posts.length === 0">
      <empty-card>
      </empty-card>
    </div>
    <v-window-item v-for="(post, index ) in posts" :key="post.id">
      <card-item
        :assetUrl="post.data().assetUrl"
        :assetType="post.data().type"
        :avatarImgUrl="post.data().creatorAvatarUrl"
        :avatarName="post.data().creatorName"
        :msg="post.data().message"
        :cardWidth="1000"
        :videoAutoplay="false"
        :videoControls="false"
        :videoLoop="true"
        :assetHeight="'480px'"
        :reference="index"
        :postId="post.id"
        :visited="post.data().visited"
      />
    </v-window-item>
  </v-window>
</template>

<script>

import { Firestore } from '../../firebase'
import CardItem from '../cards/CardItem.vue'
import { mapGetters } from 'vuex'
import EmptyCard from '../cards/EmptyCard.vue'
import { VideoCallStatus } from '../../views/enums/VideoCallStatus'

export default {
  components: {
    CardItem, EmptyCard
  },
  data () {
    return {
      posts: []
    }
  },
  computed: {
    ...mapGetters({
      user: 'user',
      family: 'family'
    })
  },
  mounted () {
    this.GetPosts()
    this.CheckVideoCall()
  },
  methods: {
    GetPosts () {
      if (this.user.loggedIn && this.family.data.id !== null && this.family.data.id !== '') {
        Firestore
          .collection('posts')
          .where('familyId', '==', this.family.data.id)
          .orderBy('date', 'desc')
          .limit(20)
          .onSnapshot(querySnapshot => {
            this.posts = []
            querySnapshot.forEach(doc => {
              this.posts.push(doc)
            })
          })
      }
    },
    CheckVideoCall () {
      if (this.user.loggedIn && this.family.data.id !== null && this.family.data.id !== '') {
        Firestore
          .collection('videocalls')
          .where('familyId', '==', this.family.data.id)
          .where('status', '==', VideoCallStatus.Calling)
          .onSnapshot(querySnapshot => {
            if (querySnapshot.size === 1) {
              this.$router.push({ name: 'joincall', params: { id: querySnapshot.docs[0].id, callId: querySnapshot.docs[0].data().videoCallId, avatarName: querySnapshot.docs[0].data().avatarName, avatarImgUrl: querySnapshot.docs[0].data().photoURL } })
            }
          })
      }
    },
    CheckLoginState () {
      if (!this.user.loggedIn) {
        this.$router.push('/login')
      }
    }
  }
}
</script>
<style lang="scss">
@import '../../assets/styles/_variables.scss';
.bg-specialgreen {
  background-color: $card-color;
}
</style>
