
<template>
    <v-layout class="mx-auto justify-center">
      <div
        v-if="assetType === 'video' || assetType === 'image' "
      >
        <asset-card
          :assetUrl="assetUrl"
          :assetType="assetType"
          :avatarImgUrl="avatarImgUrl"
          :avatarName="avatarName"
          :msg="msg"
          :cardWidth="cardWidth"
          :videoAutoplay="videoAutoplay"
          :videoControls="videoControls"
          :videoLoop="videoLoop"
          :assetHeight="assetHeight"
          :reference="reference"
        />
      </div>
      <div v-else-if="assetType === null">
        <no-asset-card
          :avatarImgUrl="avatarImgUrl"
          :avatarName="avatarName"
          :msg="msg"
          :cardWidth="cardWidth"
          :assetHeight="assetHeight"
          :reference="reference"
        />
      </div>
      <div v-else>
      </div>
    </v-layout>
</template>

<script>

import AssetCard from '../cards/AssetCard.vue'
import NoAssetCard from '../cards/NoAssetCard.vue'
import { Firestore } from '../../firebase'
import { mapGetters } from 'vuex'

export default {
  name: 'CardItem',
  props: {
    assetUrl: String,
    assetType: String,
    avatarImgUrl: String,
    avatarName: String,
    msg: String,
    cardWidth: Number,
    videoControls: Boolean,
    videoAutoplay: Boolean,
    videoLoop: Boolean,
    assetHeight: String,
    reference: Number,
    postId: String,
    visited: Boolean
  },
  components: {
    AssetCard, NoAssetCard
  },
  computed: {
    ...mapGetters({
      user: 'user'
    })
  },
  mounted () {
    this.UpdatePostVisited()
  },
  methods: {
    UpdatePostVisited () {
      // No need to update visited state when already visited
      if (!this.user.loggedIn || this.visited) {
        return
      }
      Firestore
        .collection('posts')
        .doc(this.postId)
        .update({
          visited: true
        })
        .then(
          console.log('Post ' + this.postId + ' visited')
        )
    }
  }
}
</script>
